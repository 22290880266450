import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../../components/layout";
import style from "./rules.module.scss";

import RulesMma from '../../assets/images/rules_mma.png';
import RulesNoGi from '../../assets/images/rules_nogi.jpg';

class Trainer extends React.Component {
  render() {
    return (
      <Layout pagename="Unsere Regelwerke">
        <Container>
          <Row className={style.row}>            
            <Col className={style.col1}>
              <a href={RulesMma} target="_blank" rel="noopener noreferrer">
                <img
                  src={RulesMma}
                  alt={"RulesMma"}
                  className={style.img}
                />
              </a>
            </Col>
          </Row>

          <Row className={style.row}>            
            <Col className={style.col1}>
              <a href={RulesNoGi} target="_blank" rel="noopener noreferrer">
                <img
                  src={RulesNoGi}
                  alt={"RulesNoGi"}
                  className={style.img}
                />
              </a>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default Trainer;
