import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
import style from './header.module.scss';
import Logo from '../../../assets/images/_Logo.png';
import data from '../../../assets/data.json';

class NavBar extends React.Component {
  constructor(props) {
    super(props)

    this.refHeader = React.createRef();
    this.state = {
      isOpen: false,
      height: 0,
      pagename: ''
    }
  }

  toggle = () => this.setState({isOpen: !this.state.isOpen});

  componentDidMount () {    
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  scrollAnker = (e) => {
    let scrollY = e.screenY;
    window.scrollTo(0, scrollY); 
  }

  getSportsNavs = () => {
    let DropdownItems = [];
    data.martialArts.forEach((martialArt, i) => {
      if (martialArt.hideme) {return}
      DropdownItems.push(
        <DropdownItem key={martialArt.anker} className={style.dropdownItem}>
          <NavLink href={"/sports#" + martialArt.anker}>{ martialArt.name } </NavLink>
        </DropdownItem>
      )
    });
    return DropdownItems;
  }

  getHeader = () => {
    return (
      <div {...this.props} ref={this.refHeader}>
        <Navbar id="navbar" expand="lg" className={style.navBar}>
          <NavbarBrand className={["d-inline d-lg-none", style.brandMobile].join(' ')} href="/">            
            <img src={Logo} alt="German Amateur Mixed Martial Arts Federation Mecklenburg Vorpommern e.V." title="German Amateur Mixed Martial Arts Federation Mecklenburg Vorpommern e.V." className={style.imgLogo} />
          </NavbarBrand>

          <NavbarToggler onClick={this.toggle} className={style.navToggler} />

          <Collapse isOpen={this.state.isOpen} navbar className={[style.collapse, "float-lg-right loat-md-right"].join(' ')}>
            <Nav className={style.center} navbar>

              <NavbarBrand href="/" className={["d-none d-lg-inline", style.brand].join(' ')}>
                <img src={Logo} alt="German Amateur Mixed Martial Arts Federation Mecklenburg Vorpommern e.V." title="German Amateur Mixed Martial Arts Federation Mecklenburg Vorpommern e.V." className={style.imgLogo} />
              </NavbarBrand>
              
              <div className={style.line}></div>

              <UncontrolledDropdown nav inNavbar className={style.navItem} color="dark">
                <DropdownToggle nav caret>
                  Der Verband
                </DropdownToggle>
                <DropdownMenu right className={style.dropdown}>
                  <DropdownItem key="1" className={style.dropdownItem}>
                    <NavLink href="/verband"> Über Uns </NavLink>
                    <NavLink href="/vorstand"> Vorstand </NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <div className={style.line}></div>    

              <NavItem className={style.navItem}>
                <NavLink href="/join">Mitglied werden</NavLink>
              </NavItem>

              <div className={style.line}></div>    

              <NavItem className={style.navItem}>
                <NavLink href="/calendar">Kalender</NavLink>
              </NavItem>

              <div className={style.line}></div>    

              <NavItem className={style.navItem}>
                <NavLink href="/partner">Unsere Partner</NavLink>
              </NavItem>

              <div className={style.line}></div>    

              <NavItem className={style.navItem}>
                <NavLink href="/ausbildung">Ausbildung</NavLink>
              </NavItem>

              <div className={style.line}></div>    

              <NavItem className={style.navItem}>
                <NavLink href="/rules">Regelwerke</NavLink>
              </NavItem>

              <div className={style.line}></div>    

              <NavItem className={style.navItem}>
                <NavLink href="contact">Kontakt</NavLink>
              </NavItem>

            </Nav>
          </Collapse>
        </Navbar>
      </div>
    )
  }

  render () {
    return(
      this.getHeader()
    )
  };
}

export default NavBar;