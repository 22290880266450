import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from '../../components/layout';

function Impressum() {
  return (
    <Layout pagename="Impressum">
      <Container>
        <Row>
          <Col>
            <h1>Impressum</h1>
            <p>
              Anbieter: <br/>
              Dennis Schulz <br/>
              Kuhdamm 3a <br/>
              17033 Neubrandenburg <br/>
              <br/>
              Kontakt: <br/>              
              Telefon: 01517 0099473 <br/>
              E-Mail: <a href="mailto:mail@gammaf-mv.de">info@gammaf-mv.de</a> <br/>
              Website: <a href="https://www.gammaf-mv.de">wwww.gammaf-mv.de</a>
            </p>
            <hr/>
            Das Impressum enthält die gesetzlich vorgeschriebenen Angaben über Verantwortlichkeit und Zuständigkeit für diese Website. Bei Fragen zu unserer Website wenden Sie sich bitte an die angegebenen Kontaktdaten.
            <h5>Haftungsausschluss</h5>
            Die auf den Webseiten von Dennis Schulz zur Verfügung gestellten Inhalte werden unter Beachtung größter Sorgfalt laufend auf Richtigkeit und Aktualität überprüft. Eine zwischenzeitliche Veränderung der angegebenen Daten kann nicht gänzlich ausgeschlossen werden. Eine Haftung oder Garantie für die Richtigkeit, Vollständigkeit und Aktualität kann daher nicht übernommen werden. Gleiches gilt für alle anderen Webseiten, auf die mittels Verknüpfung verwiesen wird. Für den Inhalt der verlinkten Webseiten ist der jeweilige Anbieter selbst verantwortlich.
            <br />
            Änderungen, Ergänzungen oder Löschung der von Dennis Schulz bereitgestellten Informationen können jederzeit durch den Gesellschafter vorgenommen werden. Alle auf den Webseiten genannten Markennamen, Produktbezeichnungen und Firmenlogos sind eingetragene Warenzeichen und Eigentum der jeweiligen Rechteinhaber.
            <h5>Verweise und Links auf externe Seiten</h5>
            Mit Urteil vom 12. Mai 1998 hat das Landgericht Hamburg entschieden, dass durch die Ausbringung eines Links die Inhalte der gelinkten Seite ggfls. mit zu verantworten sind. Dies kann - so das LG - nur durch ausdrückliche Distanzierung von diesen Inhalten verhindert werden. Dennis Schulz betont ausdrücklich, dass sie keinerlei Einfluss auf Gestaltung und/oder Inhalte der gelinkten Seiten hat. Diese Erklärung gilt für alle auf unseren Webseiten ausgebrachten Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
            <h5>Copyright</h5>
            Das Urheberrecht aller von Dennis Schulz selbst erstellten Texte und Bilder bleibt allein beim Gesellschafter. Das Kopieren, Speichern oder Weiterleiten ist nicht erlaubt, d.h. eine Vervielfältigung oder Verwendung von Texten, Bildern oder anderer Mediadatein ohne ausdrückliche schriftliche Zustimmung von Dennis Schulz ist nicht gestattet.
            <h5>Datenschutz</h5>
            Zur Wahrung Ihrer Privatsphäre ist die Nutzung unserer Webseiten grundsätzlich ohne Angabe geschäftlicher, persönlicher und privater Daten möglich. Wenn innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten besteht, werden diese vertraulich und gemäß den gesetzlichen Bestimmungen behandelt. Dennis Schulz nutzt Ihre Daten zu Zwecken der technischen Administration der Webseiten und zur Kundenverwaltung. In Verbindung mit Ihrem Zugriff werden gegebenenfalls auf dem Server Daten für Sicherungszwecke gespeichert (IP-Adresse, Datum, Uhrzeit). Die statistische Auswertung anonymisierter Datensätze bleibt vorbehalten. Diese Datenschutzerklärung bezieht sich ausschließlich auf die Webseiten von Dennis Schulz.
          </Col>
        </Row>
      </Container>
    </Layout>    
  );
}

export default Impressum;