import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from '../../components/layout';

import style from './contact.module.scss';


function Contact() {
  return (
    <Layout pagename="Kontakt">
      <Container  className={style.container}>
        <Row>
          <Col md="12" lg="6" className={style.colText}>
            <b>GAMMAF-MV <br/>German Amateur Mixed Martial Arts Federation <br/>Mecklenburg Vorpommern e.V. </b>            
            <br/>
            Kuhdamm 3a
            <br/>
            17033 Neubrandenburg
            <br/>
            <br/>
            Tel.: (+49) 015170099473
            <br/>
            E-Mail: <a href="mailto:info@gammaf-mv.de">info@gammaf-mv.de</a>
            <br/><br/>
            Ansprechpartner vor Ort: Dennis Schulz
          </Col>
          <Col md="12" lg="6" className={style.colMap}>
          <iframe className={style.map} 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2369.3979078095463!2d13.244320016166249!3d53.56851478002613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47abc3227212cc73%3A0x70cced27677a34bb!2sGAMMAF-MV%20-%20German%20Amateur%20Mixed%20Martial%20Arts%20Federation%20Mecklenburg%20Vorpommern%20e.V.!5e0!3m2!1sde!2sde!4v1616404699229!5m2!1sde!2sde" 
                  width="100%" 
                  height="500" 
                  style={{border: "none"}}
                  allowFullScreen=""
                  loading="lazy"
                  title="Google Maps">            
          </iframe>
          {/* <iframe className={style.map} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2369.7708381807442!2d13.270150815959239!3d53.56185816624761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47abc3bb91686e5f%3A0xe2feee429604b602!2sAttitude%20Sports%20NB%20-%20Martial%20Arts%20Academy!5e0!3m2!1sde!2sde!4v1574631652386!5m2!1sde!2sde" 
            width="100%" 
            height="500" 
            frameborder="0" 
            style={{border: "none"}}
            allowfullscreen="true"
            title="Google Map">
          </iframe> */}
          </Col>
        </Row>
      </Container>
    </Layout>    
  );
}

export default Contact;