import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../../components/layout";
import style from "./verband.module.scss";

class Trainer extends React.Component {
  render() {
    return (
      <Layout pagename="Die GAMMAF-MV German Mixed Martial Arts Federation Mecklenburg Vorpommern e.V.">
        <Container>
          <Row className={style.row}>
            <Col>
              <h2>Eine Mission</h2>
              <p>
                Der German Amateur Mixed Martial Arts Federation Mecklenburg Vorpommern e.V. Kämpft für die Anerkennung des MMA-Sports in Deutschland, ohne Extremismus, der Hass oder Herabwürdigung von Menschen propagiert!
                <br/><br/>
                MMA erfreut sich weltweit immer größerer Beliebtheit. Es gibt keinen anderen Sport auf der Welt, der sich so schnell weiterentwickelt. Die Anzahl der Kämpfer und Fans wächst stetig. Die Struktur entfaltet sich in ungeahntem Ausmaß. Ob Clubs, Vereine oder Veranstaltungen und Veranstalter, ihre Zahl wird ständig größer und sie agieren immer professioneller und internationaler. Die Chancen und Möglichkeiten des MMA-Sports scheinen grenzenlos zu sein. Das Wachstum des MMA-Sports erfordert eine weltweit agierende Organisation, die alle Kräfte und das gesamte Potential des MMA-Sports in Deutschland bündelt, um so große Ziele gemeinsam zu erreichen.
                <br/><br/>
                Die GAMMAF stellt sich dieser Herausforderung mit ihrem Dachverband, der GAMMA ,UWMMA, bereits in 105 Ländern. Zusammen mit allen Sportlern, Vereinen, Clubs und Trainern wollen wir eine gemeinschaftliche Institution für alle Interessierten und Gleichgesinnten schaffen, die den deutschen MMA-Sport weltweit vertritt und seiner Stimme international Geltung verleiht.
              </p>            
            </Col>
          </Row>

          <Row className={style.row}>
            <Col>
              <h2>Ein Positives Image für den MMA-Sport</h2>
              <p>
                Die GAMMAF möchte mit Ihren Mitgliedern dazu beitragen, dem MMA-Sport endlich zu der Stellung in Deutschland zu verhelfen, die ihm gebührt. Wir sagen es mit aller Deutlichkeit: MMA hat das Potential, ein Nationalsport zu werden. Der MMA-Sport hat Anspruch darauf, gesamtgesellschaftlich anerkannt und akzeptiert zu werden, schließlich hat er seinen Ursprung in allen anderen Kampfsportarten. Kampfsportarten, die eine Jahrhunderte oder gar Jahrtausende währende Tradition haben und meist auf gemeinsamen Werten und Überzeugungen beruhen.
                <br/><br/>
                Wir möchten den Amateur MMA-Sport in Deutschland endlich ,,aus dem Käfig lassen”. Die Käfige dienen zwar der Sicherheit und sollen Stürze verhindern, dennoch haftet den Käfigkämpfen ein sehr ,,schmuddeliger” Ruf an und steht so einer breiten Akzeptanz in der Gesellschaft auf Amateurebene entgegen. Zu diesem Zweck führen wir unsere Wettkämpfe in einem Ring durch und orientieren uns somit an weltweit anerkannten Sportarten, wie dem Boxen und Kickboxen. Um bei der Sicherheit keine Abstriche zu machen, ist das mittlere Ringseil über ein Gitter mit dem Ringboden verbunden.
                <br/><br/>
                Mit einem positiven Image verbinden wir zudem, dass MMA-Sportler in Deutschland nicht Positiv auf Doping getestet werden. Was natürlich nicht heißt, dass wir keine Kontrollen durchführen, ganz im Gegenteil. Für dieses Ziel wird eine Kontrollinstitution eingerichtet. Um die Gesundheit der Sportler zu garantieren und faire Wettkämpfe zu gewährleisten, ist uns eben jedes Mittel recht. Junge, unerfahrene Sportler werden von der GAMMAF dadurch vor übereifrigen Trainern, Funktionären und Betreuern geschützt, aber auch vor sich selbst.
                <br/><br/>
                Für ein positives Bild vom MMA-Sport distanzieren wir uns von den Blutschlachten und anderen unschönen Bildern, die bei bestimmten Veranstaltungen zusehen sind. Für uns zählt der sportliche Gedanke. Die Gesundheit der Sportler hat bei uns oberste Priorität, nicht aber das Spektakel. MMA ist ein Sport für sich und keine Show für jeden Sensationslustigen. MMA braucht klare Regeln im Amateurbereich.
              </p>            
            </Col>
          </Row>

          <Row className={style.row}>
            <Col>
              <h2>Keine Konkurrenz für Sportarten und Verbände mit langer Tradition</h2>
              <p>
                Die DMMAV respektiert und schätzt bestehende Strukturen und Traditionen.  Wir sind an einer fruchtbaren Zusammenarbeit mit anderen Verbänden, Vereinen und Sportarten interessiert. Wir möchten nicht konkurrieren. Wir würdigen jede Sportart und wünschen uns im Interesse aller Sportler, dass man an den Gemeinsamkeiten arbeitet und festhält. Was verbindet, das verbindet.
              </p>            
            </Col>
          </Row>

          <Row className={style.row}>
            <Col>
              <h2>MMA für Amateure, den Nachwuchs,  die Damenwelt und den Breitensport</h2>
              <p>
                Die GAMMAF veranstaltet zukünftig nationale Meisterschaften und bietet die Möglichkeit an internationalen Meisterschaften, sprich Europa- und Weltmeisterschaften der GAMMA teilzunehmen, um vor Allem den Amateur- und Jugendsport zu fördern. Wir sind die einzige Organisation, die diese beiden Gruppen (Amateure/Nachwuchs), bei der Festlegung seiner Standards und Regeln besonders berücksichtig und schützt. Dies beinhaltet vor Allem, dass der Sport nur mit entsprechender Schutzausrüstung (Handschue, Schienbeinschoner, und Kopf- und Tiefschutz) durchgeführt wird. Unser Regelwerk beruht auf olympischen Statuten. So möchten wir dem Nachwuchs und den Amateuren schon früh die Möglichkeit geben, sich zu entwickeln und auf internationalem Parket Erfahrungen zu sammeln. Der Damenwelt öffnen wir damit ebenfalls die Tür zum MMA-Sport.
                <br/><br/>
                Aufgrund unseres Regelwerkes und unseren Prinzipien, welche Verletzungen vorbeugen, ist der MMA-Sport auf nationaler und internationaler Wettkampfebene nun auch etwas für den ambitionierten Breitensportler. MMA bietet auch ohne Wettkampfteilnahme durch seine umfangreichen Trainingsanforderungen eine ausgezeichnete Möglichkeit, sich fit und gesund zu halten. Mixed Martial Arts ist Sport und Leidenschaft.
                <br/>
                Jeder Sportler kann sich in diesem Sport sein Spezialgebiet erarbeiten, ob Stand, Übergang vom Stand zum Boden und der Boden lassen keine grenzen entstehen.
              </p>            
            </Col>
          </Row>

          <Row className={style.row}>
            <Col>
              <h2>Eine Amateurstruktur als gesunde Basis</h2>
              <p>
                Dem MMA-Sport in Deutschland fehlt es an einer gesunden und breit aufgestellten Amateurstruktur. Für eine positive sportliche und persönliche Entwicklung, bildet diese die erforderliche Basis. Um den MMA-Sport als eigene Sportart zu definieren ist es wichtig, alle anderen Kampfsportarten zu würdigen und Ihre Werte weiter zu vermitteln. Ohne richtige Amateurstruktur ist dies jedoch kaum möglich. Statt dessen entwickelt sich eine Klientel von Scheinprofis, für die nicht der sportliche Wettbewerb oder die eigene Entwicklung vorderrangig ist, sondern die Show und die Gage bei einem Kampf. Der MMA Sport in Deutschland wird von öffentlichen Institutionen verschmäht und unterliegt keinen Regularien oder Kontrollen. Der MMA-Sport in Deutschland bewegt sich demnach in einer Grauzone. Das ist nicht das was unsere Kinder Verdient haben, wenn sie sich trotz aller Widerstände für diesen Sport begeistern. Mit einer gesunden Amateurstruktur geben wir dem MMA-Sport die Würde, für eine würdevolle Jugend.
              </p>            
            </Col>
          </Row>

          <Row className={style.row}>
            <Col>
              <h2>Eine historische Chance</h2>
              <p>
                „We are All one“ ist der Wunsch den die meisten Sportler in Ihrem Herzen tragen. Dieser Wunsch nach Einigkeit wird in vielen Sportarten durch Machtkämpfe auf immer und ewig ein Traum bleiben. Machtkämpfe aus Eigennutz, weil ja schließlich jeder seinen eigenen Weltmeister haben und die Sportwelt mit seinem, dem hundertsten, Champion beglücken möchte. Wir haben die historische Chance, dies für den MMA-Sport besser zu machen. We are All one!
              </p>            
            </Col>
          </Row>

          <Row className={style.row}>
            <Col>
              <h2>Ein Sprungbrett</h2>
              <p>
                Perspektiven sind ein wichtiger Anreiz. Die GAMMAF bietet allen ambitionierten Kämpfern aus Deutschland den direkten Weg zu Profisportler. Wer alle Entwicklungsmöglichkeiten nutzt, hat später die Chance auf eine Profikarriere, bei einer der renommiertesten Veranstaltungsserien der Welt. Natürlich steht es jedem Sportler frei, wie er seine Kariere gestaltet und welchen Weg er einschlägt. Die GAMMAF unterstützt ihre Mitglieder in jeder Hinsicht.
              </p>            
            </Col>
          </Row>

          <Row className={style.row}>
            <Col>
              <h2>Für Vereine und Clubs</h2>
              <p>
                Profitieren Sie als Verein oder Club von Ihrer Verbands-Mitgliedschaft. Machen Sie Ihren Club oder Verein attraktiv für neue Mitglieder und nutzen Sie unsere Leistungen. Nehmen Sie mit Ihren Mitgliedern an deutschlandweiten oder internationalen Turnieren und Meisterschaften teil. Besuchen Sie mit uns Schulungen und Kaderlehrgänge mit national und international bekannten Sportgrößen. Veranstalten Sie mit uns Trainingslager. Profitieren Sie von dem Angebot für den Amateur.- und Jugendbereich, den man in Deutschland so vergebens sucht. Halten Sie alte oder ziehen Sie neue Mitglieder an, durch die Chancen auf eine Profikarriere. Profitieren Sie selbst von einer Profikarriere und betreuen und trainieren Sie Ihren Schützling weiter.
              </p>            
            </Col>
          </Row>

          <Row className={style.row}>
            <Col>
              <h2>Der Olympische Gedanke </h2>
              <p>
                MMA als der weltweit am schnellsten wachsende Sport, erreicht mit seiner Popularität ein beispielloses Niveau auf allen Kontinenten und Ebenen des Sports. Die Zahl seiner Anhänger steigt stetig. Aber auch die Zahl der aktiven MMA-Sportler, aus vor Allem dem MMA verwandten Sportarten, schießt in die Höhe und unterstreicht so den olympischen Gedanken des MMA-Sports, die Menschen zusammen zuführen.
                <br/><br/>
                Alle olympischen Kampfsportdisziplinen werden entweder auf Tatami oder im Ring ausgetragen. Eben drum finden auch alle Wettkämpfe der GAMMA und der GAMMAF in einem Ring statt. Der Ring steht für unser Ziel,  Teil der Gemeinschaft, der 5 olympischen Ringe zu werden. Es ist unsere Vision, den MMA-Sport in aller Welt als offiziellen Nationalsport zu etablieren. Mehrere große europäische und asiatische Länder gehen mit gutem Beispiel voran, indem sie in Zusammenarbeit mit ihren jeweiligen Sportministerien, dem MMA-Sport, den Status eines offiziell anerkannten Sportes auf nationaler Ebene verleihen. Mit großer Leidenschaft verfolgen wir das Ziel MMA zu einer olympischen Sportart/Disziplin zu erheben und Mitglied in der GAISF zu werden. Die komplette Struktur, die Normen und Richtlinien für Wettkämpfe und Meisterschaften, sowie das Regelwerk sind ausgerichtet für Olympia. Das ist der größte Traum und die größte Ehre, die man für seinen Sport und alle seine Verfechter erreichen kann. Unterstützen auch Sie diese Idee. Helfen Sie uns, das olympische Feuer auch für den MMA-Sport zu entfachen und im Herzen aller seiner Mitglieder brennen zu lassen.
              </p>            
            </Col>
          </Row>

          <Row className={style.row}>
            <Col>
              <h2>Harte Arbeit </h2>
              <p>
                Sportler, die Erfolg haben wollen, müssen hart an sich und für ihren Erfolg arbeiten. Wir nehmen unsere Mission sehr ernst und werden mit Durchhaltevermögen und harter Arbeit alles daran setzen, die gesteckten Ziele für ihre Mitglieder und den gesamten MMA-Sport in Deutschland zu erreichen. Ganz nach dem Motto: „Hart aber Herzlich“
               </p>            
            </Col>
          </Row>

          <Row className={style.row}>
            <Col>
              <h2>Ehrliche Arbeit </h2>
              <p>
                Im Sport spielt Fairness und Respekt einem Anderen gegenüber eine außerordentlich wichtige Rolle. Das hat sich auch die GAMMAF auf die Fahne geschrieben. Wir treten allen offen, fair und mit Respekt gegenüber, um dem MMA-Sport ein positives Image zu verleihen und jedem mit unserer ehrlichen Arbeit zu überzeugen. Wir möchten ein Vorbild für den MMA-Sport weltweit sein. Die GAMMAF ist als e.V. eine gemeinnützige, und Non-Profit Organisation, die ausschließlich ideelle und gemeinnützige Ziele verfolgt. Die Rechnungsbücher stehen jedem offen, der davon überzeugt werden möchte. Unsere Arbeit basiert auf Offenheit, Gemeinschaft und Vertrauen. Ganz nach dem Motto: „We are all one“
              </p>            
            </Col>
          </Row>

          <Row className={style.row}>
            <Col>
              <h2>Innovative Arbeit </h2>
              <p>
                Der MMA-Sport an sich ist eine Innovation. Er führt das Beste aus allen Kampfsportarten zusammen und entwickelt sich ständig weiter. Das betrifft sowohl Techniken, als auch Persönlichkeiten. Wie der MMA-Sport wird auch die GAMMAF das Beste und die Besten in einer Institution zusammenführen, um etwas Großes, Neues und Innovatives zu schaffen. Der Verband steht jedem offen, der seinen Beitrag dazu leisten möchte und nimmt Ratschläge und Kritik dankend an. Wir versuchen uns immer weiterzuentwickeln und besser zu werden. Ganz nach dem Motto: „Wer aufgehört hat besser zu werden, hat aufgehört gut zu sein“.
              </p>            
            </Col>
          </Row>

          <Row className={style.row}>
            <Col>
              <h2>Zuverlässige Arbeit mit Beständigkeit </h2>
              <p>
                Jeder Sportler braucht nicht nur zuverlässiges Equipment mit Beständigkeit, um seine Ziele zu erreichen, sondern auch Menschen und Institutionen, die hinter ihm stehen und ihm den Weg bereiten. Das Gleiche gilt natürlich für Trainer, Betreuer, Vereine und Clubs. Die GAMMAF bildet die verlässliche Gemeinschaft mit Beständigkeit, die es so dringend braucht,  für den Deutschen MMA Amateur- und Jugendsport.
              </p>            
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default Trainer;
