import React from "react";
import { Container, Row, Col} from "reactstrap";
import Layout from "../../components/layout";
import style from "./calendar.module.scss";
import axios from 'axios'
import Scheduler from 'devextreme-react/scheduler';

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: null,
      adaptivityEnabled: false
    }

    this.currentDate = this.getDate(new Date().toDateString()).dateArray;
    this.views = ['day', 'week', 'month']; //, 'agenda'
    this.CALENDAR_ID = 'fg8g4c9l80hpfg2i2i0t5hrj74@group.calendar.google.com';
    this.CALENDAR_KEY = 'AIzaSyAqMhfp5InoVyt0y5JcX1E3tnIEvesM--k';
    this.CALENDAR_URL = new URL(`https://www.googleapis.com/calendar/v3/calendars/${this.CALENDAR_ID}/events`);
  }

  getData(requestOptions) {
    this.CALENDAR_URL.search = new URLSearchParams(requestOptions);
  
    axios.get(this.CALENDAR_URL).then(response => {
      this.setState({dataSource: response.data.items})
    });
  }

  getDate(dateString, monthModifyer = 0, clearDay = false) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dateArray = dateString.split(" ");
    var intDay = clearDay ? 1 : parseInt(dateArray[2]);
    var intMonth = parseInt(monthNames.indexOf(dateArray[1])) + 1 + monthModifyer ;
    var intYear = parseInt(dateArray[3]);    
    
    if (intMonth > 12) {
      intMonth -= 12;
      intYear += 1
    } else if (intMonth < 1) {
      intMonth += 12;
      intYear -= 1
    }

    return {
      day: intDay,
      month: intMonth,
      year: intYear,
      dateArray: [intYear, intMonth, intDay],
      getNextMonth: () => {return this.getDate(new Date([intYear, intMonth, intDay]).toDateString(), 1)},
      getPrevMonth: () => {return this.getDate(new Date([intYear, intMonth, intDay]).toDateString(), -1)}
    }
  }

  updateCalendar(dateString) {
    var minDate = this.getDate(dateString, -1, true);
    var maxDate = this.getDate(dateString, 2, true);

    this.getData({
      key: this.CALENDAR_KEY,
      showDeleted: 'false',
      singleEvents: 'true',
      timeMax: `${maxDate.year}-${maxDate.month}-${maxDate.day}T23:59:59Z`,
      timeMin: `${minDate.year}-${minDate.month}-${minDate.day}T00:00:00Z`
    });
  }

  onInitialized(e, that) {
    this.updateCalendar(new Date().toDateString());
  }

  onOptionChanged(e, that) {
    if(e.name === 'currentDate') {
      this.updateCalendar(e.value.toString());
    }
  }

  render() {
    return (
      <Layout pagename="Kalender">
        <Container>
          <Row className={style.row}>
            <Col>
              <React.Fragment>
                <Scheduler
                  dataSource={this.state.dataSource}
                  views={this.views}
                  defaultCurrentView="month"
                  defaultCurrentDate={this.currentDate}
                  height={800}
                  startDayHour={7}
                  editing={false}
                  showAllDayPanel={true}
                  startDateExpr="start.dateTime"
                  endDateExpr="end.dateTime"
                  textExpr="summary"
                  agendaDuration={35}
                  firstDayOfWeek={1}
                  adaptivityEnabled={this.state.adaptivityEnabled}
                  showCurrentTimeIndicator={true}
                  onInitialized={(e) => {this.onInitialized(e, this)}}
                  onOptionChanged={(e) => {this.onOptionChanged(e, this)}}
                  timeZone="Europe/Berlin">
                </Scheduler>
              </React.Fragment>
             </Col>
           </Row>
         </Container>
       </Layout>
    );
  }
}

export default Calendar;
