import React from 'react';
import style from './pagepath.module.scss';

class PagePath extends React.Component {
  render() {
    return (
      <div className={style.pagePath}>
        <h1>
          {this.props.pagename}
        </h1>
      </div>
    );
  }    
}

export default PagePath;