import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../../components/layout";
import style from "./partner.module.scss";

import Gammaf from '../../assets/images/gammaf.png';
import Gamma from '../../assets/images/gamma.png';
import Nyx from '../../assets/images/nyx.jpeg';

import Asnb from '../../assets/images/asnb.png';
import Zrteam from '../../assets/images/zrteam.jpg';

class Trainer extends React.Component {
  render() {
    return (
      <Layout pagename="Unsere Partner">
        <Container>
        <Row className={style.row}>
            <Col className={style.colGamma} md="4">
              <a href="https://gamma-sport.org/" target="_blank" rel="noopener noreferrer">
                <img
                  src={Gamma}
                  alt={"Gamma"}
                  className={style.img}
                />
              </a>
            </Col>
            <Col md="8" className={style.pad15}>
              <h2>GAMMA Global Association of Mixed Martial Arts</h2>
              <p>
                GAMMA has the long-term ambition to obtain international recognition for mixed martial arts as an Olympic sport, giving all amateur mixed martial artists the platform and opportunity to fulfill their dreams of competing on the Olympic stage. Our five-year strategy through 2024 is the first step on that journey.
              </p>
            </Col>
          </Row>

          <Row className={style.row}>
            <Col className={style.col1} md="4">
              <a href="https://gammaf.de/" target="_blank" rel="noopener noreferrer">
                <img
                  src={Gammaf}
                  alt={"Gammaf"}
                  className={style.img}
                />
              </a>
            </Col>
            <Col md="8" className={style.pad15}>
              <h2>GAMMAF German Amateur Mixed Martial Arts Federation</h2>
              <p>
                Der German Amateur Mixed Martial Arts Federation e.V. Kämpft für die Anerkennung des MMA-Sports in Deutschland. Gemeinsam mit unserem Weltverband der GAMMA und über 105 anderen Ländern streben wir außerdem danach, MMA zu einer olympischen Sportart zu machen. Werden auch Sie Teil dieser Vision und unterstützen Sie uns als Mitglied.
              </p>
            </Col>
          </Row>

          <Row className={style.row}>
            <Col className={style.col1} md="4">
              <a href="https://nyx-fightwear.com/" target="_blank" rel="noopener noreferrer">
                <img
                  src={Nyx}
                  alt={"Nyx"}
                  className={style.img}
                />
              </a>
            </Col>
            <Col md="8" className={style.pad15}>
              <h2>Nyx Fightwear</h2>
              <p>
                A new project to let the BJJ community grow.
                <br/>
                Get fair BJJ fightwear for affordable prices.
                <br/>
                Choose Nyx Fightwear artworks or get customized products.
                <br/>
                Every product helps to sponsor young grappling athletes.
              </p>
            </Col>
          </Row>

          <Row className={style.ueberschrift}>
            <h1>Unsere Mitglieder</h1>
          </Row>

          <Row className={style.row}>
            <Col className={style.col1} md="4">
              <a href="https://www.attitudesports.de" target="_blank" rel="noopener noreferrer">
                <img
                  src={Asnb}
                  alt={"Asnb"}
                  className={style.img}
                />
              </a>
            </Col>
            <Col md="8" className={style.pad15}>
              <h2>Attitude Sports NB - Martial Arts Academy</h2>
              <p>
                Das Gym von Attitude Sports wurde als diy Konzept gegründet. Das bedeutet, dass die Arbeit ehrenamtlich erfolgt und jeglicher Gewinn wieder in Equipment und Seminare gesteckt wird. Grundlage war eine Gruppe, welche Bock auf Kampfsport und keine Angst hatte etwas zu riskieren, um sich den Traum vom eigenen Trainingscenter zu erfüllen. Als wichtigster Tipp, der zum Nachmachen anregen soll, haben sie uns daher „machen, machen, machen“ mit auf den Weg gegeben!
              </p>
            </Col>
          </Row>

          <Row className={style.row}>
            <Col className={style.col1} md="4">
              <a href="https://smoothcomp.com/en/club/24041" target="_blank" rel="noopener noreferrer">
                <img
                  src={Zrteam}
                  alt={"Zrteam"}
                  className={style.img}
                />
              </a>
            </Col>
            <Col md="8" className={style.pad15}>
              <h2>ZR Team Germany (Greifswald)</h2>
              <p>
                
              </p>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default Trainer;
