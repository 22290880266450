import React from 'react';
import { Switch, Route, Router } from "react-router-dom";
import { createBrowserHistory } from 'history';

import Home from '../../pages/home';
import Vorstand from '../../pages/vorstand';
import Rules from '../../pages/rules';
import Verband from '../../pages/verband';
import Join from '../../pages/join';
import Calendar from '../../pages/calendar';
import Partner from '../../pages/partner';
import Ausbildung from '../../pages/ausbildung';
import Contact from '../../pages/contact';
import Impressum from '../../pages/impressum';
import Datenschutz from '../../pages/datenschutz';

class ContentRouter extends React.Component {
  render() {
    const history = createBrowserHistory();    

    return (
      <Router history={history}>
        <Switch>
            <Route exact path="/">
              <Home />
            </Route>

            <Route path="/verband">
              <Verband />
            </Route>
            <Route path="/vorstand">
              <Vorstand />
            </Route>

            <Route path="/join">
              <Join />
            </Route>

            <Route path="/calendar">
              <Calendar />
            </Route>

            <Route path="/rules">
              <Rules />
            </Route>

            <Route path="/partner">
              <Partner />
            </Route>

            <Route path="/ausbildung">
              <Ausbildung />
            </Route>

            <Route path="/contact">
              <Contact />
            </Route>

            <Route path="/impressum">
              <Impressum />
            </Route>
            
            <Route path="/datenschutz">
              <Datenschutz />
            </Route>

            <Route>
              <Home />
            </Route>          
        </Switch>
      </Router>
    );
  }  
}

export default ContentRouter;