import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../../components/layout";
import style from "./ausbildung.module.scss";

import Ausbildung from '../../assets/images/ausbildung.jpg';

class Trainer extends React.Component {
  render() {
    return (
      <Layout pagename="Unser Ausbildungsprogramm">
        <Container>
          <Row className={style.row}>
            <Col className={style.col1} md="4">
              <img
                src={Ausbildung}
                alt={"Ausbildung"}
                className={style.img}
              />
            </Col>
            <Col md="8" className={style.pad15}>
              <p>Die Ausbildungsprogramme werden von unserem Dachverband <a href="https://gammaf.de/" target="_blank" rel="noopener noreferrer">GAMMAF e.V.</a> betreut.</p>
              <h2>
                <a href="https://gammaf.de/ausbildung/judge-referee" target="_blank" rel="noopener noreferrer">
                  JUDGE & REFEREE
                </a>
              </h2>
              <h2>
                <a href="https://gammaf.de/ausbildung/cutman-ringside-physician" target="_blank" rel="noopener noreferrer">
                  CUTMAN/RINGSIDE PHYSICIAN
                </a>
              </h2>  
              <h2>
                <a href="https://gammaf.de/ausbildung/mma-instruktor" target="_blank" rel="noopener noreferrer">
                  MMA INSTRUKTOR
                </a>
              </h2>  
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default Trainer;
