import React from 'react';
import { Link } from 'react-router-dom';
import style from './footer.module.css';

import imgFb from '../../../assets/images/fb.jpeg';
import imgInsta from '../../../assets/images/insta.png';
import imgTwitter from '../../../assets/images/twitter.svg';
import imgTumblr from '../../../assets/images/tumblr.svg';
// import imgYt from '../../../assets/images/yt.svg';
import imgMaps from '../../../assets/images/maps.png';

class Footer extends React.Component {
  render () {
    return (
        <div {...this.props}>
          <Link className={style.link} to="/impressum">Impressum</Link>
          <Link className={style.link} to="/datenschutz">Datenschutz</Link>

          <div className={style.footerLinks}>
            <a className={style.link} title="Facebook" href="https://www.facebook.com/gammafmv/" target="_blank" rel="noopener noreferrer">
              <img className={style.icon} src={imgFb} alt="Facebook" />
            </a>
            <a className={style.link} title="Instagram" href="https://www.instagram.com/gammaf_mv/" target="_blank" rel="noopener noreferrer">
              <img className={style.icon} src={imgInsta} alt="Instagram" />
            </a>
            <a className={style.link} title="Twitter" href="https://twitter.com/gammafmv" target="_blank" rel="noopener noreferrer">
              <img className={style.icon} src={imgTwitter} alt="Twitter" />
            </a>
            <a className={style.link} title="Tumblr" href="https://www.tumblr.com/blog/gammaf-mv" target="_blank" rel="noopener noreferrer">
              <img className={style.icon} src={imgTumblr} alt="Tumblr" />
            </a>
            
            {/* <a className={style.link} title="YouTube" href="https://www.youtube.com/channel/UCQczhCmq1oqKXnnuTX1PzsA" target="_blank" rel="noopener noreferrer">
              <img className={style.icon} src={imgYt} alt="YouTube" />
            </a> */}
            <a className={style.link} title="Google Maps" href="https://g.page/gammaf-mv?share" target="_blank" rel="noopener noreferrer">
              <img className={style.icon} src={imgMaps} alt="Google Maps" />
            </a>
          </div>          
        </div>
    );
  };
}

export default Footer;