import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../../components/layout";
import style from "./home.module.scss";
import SimpleImageSlider from "react-simple-image-slider";

// import Home1 from '../../assets/images/home1.jpg';
import Home2 from '../../assets/images/home2.jpg';
import Home3 from '../../assets/images/home3.jpg';
import Slide1 from '../../assets/images/slide1.jpg';
import Slide2 from '../../assets/images/slide2.jpg';
import Slide3 from '../../assets/images/slide3.jpg';
import AllOne from '../../assets/images/weareallone.png';

class Trainer extends React.Component {
  render() {
    const images = [
      { url: Slide1 },
      { url: Slide2 },
      { url: Slide3 }
    ];

    return (
      <Layout pagename="WIR SIND DIE ZUKUNFT FÜR MMA">
        <Container>
          <Row className={style.row}>            
            <Col className={style.colImg}>
              <SimpleImageSlider
                width={"100%"}
                height={400}
                images={images}
                showNavs={true}
                showBullets={true}
                slideDuration={0.5}
              />
            </Col>
          </Row>

          {/* <Row className={style.row}>            
            <Col md="8" className={style.pad15}>
              <h2>We are alle one</h2>
              <p>
              Der German Amateur Mixed Martial Arts Federation Mecklenburg Vorpommern e.V. kämpft für einen sauberen MMA Sport, ohne Extremismus, der Hass oder Herabwürdigung von Menschen propagiert!
              </p>            
            </Col>
            <Col className={style.colImg} md="4">
              <img
                src={AllOne}
                alt={"We are all one"}
                className={style.imgR}
              />
            </Col>
          </Row> */}

          <Row className={style.row}>
            <Col className={style.colImg} md="3">
              <img
                src={AllOne}
                alt={"We are all one"}
                className={style.img}
              />
            </Col>
            <Col md="9" className={style.pad15}>
              <h2>Eine Mission</h2>
              <p>
                Der German Amateur Mixed Martial Arts Federation Mecklenburg Vorpommern e.V. Kämpft für die Anerkennung des MMA-Sports in Deutschland, ohne Extremismus, der Hass oder Herabwürdigung von Menschen propagiert!
                <br/>
                Gemeinsam mit unserem Weltverband der GAMMA und über 105 anderen Ländern streben wir außerdem danach, MMA zu einer olympischen Sportart zu machen. 
                <br/>
                <a href="/join">
                  Werde auch Du Teil dieser Vision und unterstütze uns als Mitglied.
                </a>
              </p>            
            </Col>
          </Row>

          <Row className={style.row}>            
            <Col md="9" className={style.pad15}>              
              <h2>Für Vereine und Clubs</h2>
              <p>              
                Profitieren Sie als Verein oder Club von Ihrer Verbands-Mitgliedschaft. Machen Sie Ihren Club oder Verein attraktiv für neue Mitglieder und nutzen Sie unsere Leistungen. Nehmen Sie mit Ihren Mitgliedern an deutschlandweiten oder internationalen Turnieren und Meisterschaften teil. Besuchen Sie mit uns Schulungen und Kaderlehrgänge mit national und international bekannten Sportgrößen. Veranstalten Sie mit uns Trainingslager. Profitieren Sie von dem Angebot für den Amateur.- und Jugendbereich, den man in Deutschland so vergebens sucht. Halten Sie alte oder ziehen Sie neue Mitglieder an, durch die Chancen auf eine Profikarriere. Profitieren Sie selbst von einer Profikarriere und betreuen und trainieren Sie Ihren Schützling weiter.
              </p>            
            </Col>
            <Col className={style.colImg} md="3">
              <img
                src={Home2}
                alt={"Home2"}
                className={style.img}
              />
            </Col>
          </Row>

          <Row className={style.row}>            
            <Col className={style.colImg}>
              <img
                src={Home3}
                alt={"Home3"}
                className={style.imgBig}
              />
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default Trainer;
