import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../../components/layout";
import style from "./vorstand.module.scss";

import Dennis from '../../assets/images/sunny.jpg';
import Gami from '../../assets/images/gami.jpg';
import Sven from '../../assets/images/sven.jpg';
import Phia from '../../assets/images/phia.jpg';
import Kevin from '../../assets/images/kevin.jpg';
import Benni from '../../assets/images/benni.jpg';
import Torsten from '../../assets/images/torsten.jpg';

class Vorstand extends React.Component {
  render() {
    return (
      <Layout pagename="Unser Vorstand">
        <Container>
          <Row className={style.row}>
            <Col className={style.col1} md="4">
              <img
                src={Dennis}
                alt={"Dennis Schulz"}
                className={style.img}
              />
            </Col>
            <Col md="8" className={style.pad15}>
              <h2>Dennis Schulz</h2>
              <h3>Position: 1. Vorstand</h3>  
              <p>
                Headcoach bei <a href="https://www.attitudesports.de" target="_blank" rel="noopener noreferrer">
                  Attitude Sports NB - Martial Arts Academy
                </a>
              </p>            
            </Col>
          </Row>

          <Row className={style.row}>
            <Col className={style.col1} md="4">
              <img
                src={Gami}
                alt={"Gamila Kanew"}
                className={style.img}
              />
            </Col>
            <Col md="8" className={style.pad15}>
              <h2>Gamila Kanew</h2>
              <h3>Position: 2. Vorstand</h3>  
              <p>
                Headcoach bei <a href="https://smoothcomp.com/en/club/24041" target="_blank" rel="noopener noreferrer">
                  ZR Team Germany (Greifswald)
                </a>
              </p>   
            </Col>
          </Row>

          <Row className={style.row}>
            <Col className={style.col1} md="4">
              <img
                src={Sven}
                alt={"Sven Jankowski"}
                className={style.img}
              />
            </Col>
            <Col md="8" className={style.pad15}>
              <h2>Sven Jankowski</h2>
              <h3>Position: Kassenwart</h3>  
              {/* <p>
                Headcoach und Gründer von <a href="https://www.attitudesports.de" target="_blank" rel="noopener noreferrer">
                  Attitude Sports NB - Martial Arts Academy
                </a>
              </p>             */}
            </Col>
          </Row>

          <Row className={style.row}>
            <Col className={style.col1} md="4">
              <img
                src={Phia}
                alt={"Sophia Schönhoff"}
                className={style.img}
              />
            </Col>
            <Col md="8" className={style.pad15}>
              <h2>Sophia Schönhoff</h2>
              <h3>Position: Vorstandsmitglied</h3>  
              {/* <p>
                Headcoach und Gründer von <a href="https://www.attitudesports.de" target="_blank" rel="noopener noreferrer">
                  Attitude Sports NB - Martial Arts Academy
                </a>
              </p>             */}
            </Col>
          </Row>

          <Row className={style.row}>
            <Col className={style.col1} md="4">
              <img
                src={Kevin}
                alt={"Kevin Möller"}
                className={style.img}
              />
            </Col>
            <Col md="8" className={style.pad15}>
              <h2>Kevin Möller</h2>
              <h3>Position: Vorstandsmitglied</h3>  
              {/* <p>
                Kindertrainer bei <a href="https://www.psv90-neubrandenburg.de/sportarten/kampfsport/trainer-sektion-kampfsport/" target="_blank" rel="noopener noreferrer">
                  PSV90 Neubrandenburg - Avilien Ryu Jiu Jitsu
                </a>
              </p>             */}
            </Col>
          </Row>

          <Row className={style.row}>
            <Col className={style.col1} md="4">
              <img
                src={Benni}
                alt={"Benedikt Berndt"}
                className={style.img}
              />
            </Col>
            <Col md="8" className={style.pad15}>
              <h2>Benedikt Berndt</h2>
              <h3>Position: Vorstandsmitglied</h3>  
              {/* <p>
                Headcoach und Gründer von <a href="https://www.attitudesports.de" target="_blank" rel="noopener noreferrer">
                  Attitude Sports NB - Martial Arts Academy
                </a>
              </p>             */}
            </Col>
          </Row>

          <Row className={style.row}>
            <Col className={style.col1} md="4">
              <img
                src={Torsten}
                alt={"Torsten Heß"}
                className={style.img}
              />
            </Col>
            <Col md="8" className={style.pad15}>
              <h2>Torsten Heß</h2>
              <h3>Position: Vorstandsmitglied</h3>  
              {/* <p>
                Headcoach und Gründer von <a href="https://www.attitudesports.de" target="_blank" rel="noopener noreferrer">
                  Attitude Sports NB - Martial Arts Academy
                </a>
              </p>             */}
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default Vorstand;
