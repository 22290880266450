import React from "react";
import { Container, Row, Col, Form, Label, Input } from "reactstrap";
import Layout from "../../components/layout";
import style from "./join.module.scss";
import axios from 'axios';

// import Home1 from '../../assets/images/home1.jpg';

const API_PATH = '/api/contact/index.php';

class Trainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: '',
      lname: '',
      email: '',
      message: '',
      mailSent: false
    }
  }

  handleFormSubmit = e => {
    e.preventDefault();
    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: this.state
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent
        })
      })
      .catch(error => this.setState({ error: error.message }));
  };

  render() {
    return (
      <Layout pagename="Werde ein Teil der GAMMAF-MV">
        <Container>
          <Row className={style.row}>
            <Col>
              <h2>Diese Funktion wird nach der endgültigen Festsetzung unserer Finanzordnung freigeschaltet. Bitte habt noch etwas Geduld.</h2>
            </Col>
          </Row>

          <Row className={style.row} style={{display: "none"}}>
            <Col>
              <Form action="#" >
                <Label>First Name</Label>
                <Input type="text" id="fname" name="firstname" placeholder="Your name.."
                  value={this.state.fname}
                  onChange={e => this.setState({ fname: e.target.value })}
                />

                <Label>Last Name</Label>
                <Input type=" text" id="lname" name="lastname" placeholder="Your last name.."
                  value={this.state.lname}
                  onChange={e => this.setState({ lname: e.target.value })}
                />

                <Label>Email</Label>
                <Input type="email" id="email" name="email" placeholder="Your email"
                  value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })}
                />

                <Label>Message</Label>
                <Input type="textarea" id="message" name="message" placeholder="Write something.."
                  onChange={e => this.setState({ message: e.target.value })}
                  value={this.state.message}
                />
                <Input type="submit" onClick={e => this.handleFormSubmit(e)} value="Submit" />
                <div>
                  {this.state.mailSent &&
                    <div>Die Mail wurde erfolgreich verschickt. Wir werden uns zeitnah melden.</div>
                  }
                </div>
              </Form >
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default Trainer;
